async function fetchNames(params, isAShow) {
  const names = {};
  if (isAShow) {
    const response = await useRequest().get("/api/biz/config/seo/series", {
      externalSeriesId: params.seriesId,
    });
    const { seriesNameEn, seriesNameAr } = response.response[0];
    Object.assign(names, { en: seriesNameEn, ar: seriesNameAr });

    return names;
  }

  const response = await useRequest().get("/api/biz/config/seo/movies", {
    externalId: params.movieId,
  });

  const { name, nameAr } = response.response[0];
  Object.assign(names, { en: name, ar: nameAr });

  return names;
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = useNuxtApp();
  if (process.client) return;

  /**
   * there are two instances that encode the slug. so it makes sense to
   * decode it twice to be sure. decoding an already decoded (human-readable)
   * string does nothing and returns the same.
   */
  const decodedSlug = decodeURIComponent(decodeURIComponent(to.params.slug));

  const [_, locale] = to.name.split("___");

  const newPath = Object.assign({}, to.params);

  const isAShow = Object.keys(to.params).includes("seasonNumber");

  const { en: titleEn, ar: titleAr } = await fetchNames(to.params, isAShow);

  /**
   * to fix typos in the slug
   * check to.params.slug against both en and ar names
   * if it doesnt match at all, redirect with 404
   */
  if (to.params.slug !== titleEn && to.params.slug !== titleAr) {
    throw createError({
      statusCode: 404,
    });
  }

  if (locale == "en") {
    newPath.slug = titleEn;
  } else {
    newPath.slug = titleAr;
  }

  /**
   * returns freely if the slug is correct
   * ie to.params.slug is the same with the locale's name
   */
  if (decodedSlug == newPath.slug) {
    return true;
  }

  let newPathAsString;

  if (isAShow) {
    /**
     * destructure object so order is controlled
     */
    const { seriesId, slug, seasonNumber, episodeNumber } = newPath;

    newPathAsString = [
      "",
      "shows",
      seriesId,
      slug,
      seasonNumber,
      episodeNumber,
    ].join("/");
  } else {
    const isWatchPage = to.path.includes("/watch");
    const { movieId, slug } = newPath;
    newPathAsString = ["", "movies", movieId, slug];
    if (isWatchPage) {
      newPathAsString.push("watch");
    }
    newPathAsString = newPathAsString.join("/");
  }

  const localized = nuxtApp.$localePath(newPathAsString, locale);

  return navigateTo(localized, {
    redirectCode: 301,
  });
});
